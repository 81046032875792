<template>
	<router-view v-if="ready" />
	<div v-else class="text-center">
		<v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
	</div>
</template>

<script>

import "vue-toastification/dist/index.css";

export default {
	data: () => ({
		ready: false,
	}),

	beforeMount() {
		this.ready = true
	}
}
</script>
<style>
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
.display-1,
.title,
.v-application .headline,
.v-application .v-application--wrap,
.v-application .overline,
.v-application .display-3,
.v-application .display-4,
.v-application .caption {
	font-family: 'Archivo', sans-serif !important;
	letter-spacing: 0.2rem;
	color: #ccbdaf;
}

.bg-light {
	background: #f5f5f9;
}

.bg-dark {
	background: #000000;
}

.v-application .background {
	background-image: url('~@/assets/mashbg.jpg');
	background-position: center;
	background-size: cover;
}

.text-bold {
	font-weight: 900;
}

.theme--light.v-label {
	color: #ccbdaf !important;
}

.theme--light.v-text-field>.v-input__control>.v-input__slot:before {
	border-color: rgba(241, 255, 90, 0.356);
}

.text-normal {
	font-weight: normal !important;
}

.text-light {
	color: #ccbdaf;
}

.container {
	max-width: 1400px !important
}</style>