import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        component: () =>
            import ( /* webpackChunkName: "main" */ '../views/__template'),
        children: [{
                path: '',
                component: () =>
                    import ( /* webpackChunkName: "main" */ '../views/home'),
            },
            {
                path: 'buy',
                component: () =>
                    import ( /* webpackChunkName: "main" */ '../views/buy'),
            },
        ]
    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router